import { template as template_31ce2cc1fe504d3489ba49336ca3e24d } from "@ember/template-compiler";
const FKLabel = template_31ce2cc1fe504d3489ba49336ca3e24d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
